import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/themercury-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const TheMercury = (props) => (
  <Switch>
    <Route path='/mercury' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury'
        relatedSections={[{ id: 'mercury/news' }, { id: 'mercury/world' }, { id: 'mercury/environment' }, { id: 'mercury/business' }, { id: 'mercury/sport' }, { id: 'mercury/goodlife' }, { id: 'mercury/network' }, { id: 'mercury/opinion' }]}
        title='The Mercury'
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/news' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/news'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/world' }, { id: 'mercury/environment' }, { id: 'mercury/business' }, { id: 'mercury/sport' }, { id: 'mercury/goodlife' }, { id: 'mercury/network' }, { id: 'mercury/opinion' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/world' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/world'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/news' }, { id: 'mercury/environment' }, { id: 'mercury/business' }, { id: 'mercury/sport' }, { id: 'mercury/goodlife' }, { id: 'mercury/network' }, { id: 'mercury/opinion' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/environment' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/environment'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/news' }, { id: 'mercury/world' }, { id: 'mercury/business' }, { id: 'mercury/sport' }, { id: 'mercury/goodlife' }, { id: 'mercury/network' }, { id: 'mercury/opinion' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/business' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/business'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/news' }, { id: 'mercury/world' }, { id: 'mercury/environment' }, { id: 'mercury/sport' }, { id: 'mercury/goodlife' }, { id: 'mercury/network' }, { id: 'mercury/opinion' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/sport' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/sport'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/news' }, { id: 'mercury/world' }, { id: 'mercury/environment' }, { id: 'mercury/business' }, { id: 'mercury/goodlife' }, { id: 'mercury/network' }, { id: 'mercury/opinion' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/goodlife' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/goodlife'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/news' }, { id: 'mercury/world' }, { id: 'mercury/environment' }, { id: 'mercury/business' }, { id: 'mercury/sport' }, { id: 'mercury/network' }, { id: 'mercury/opinion' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/network' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/network'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/news' }, { id: 'mercury/world' }, { id: 'mercury/environment' }, { id: 'mercury/business' }, { id: 'mercury/sport' }, { id: 'mercury/goodlife' }, { id: 'mercury/opinion' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/opinion' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/opinion'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        relatedSections={[{ id: 'mercury' }, { id: 'mercury/news' }, { id: 'mercury/world' }, { id: 'mercury/environment' }, { id: 'mercury/business' }, { id: 'mercury/sport' }, { id: 'mercury/goodlife' }, { id: 'mercury/network' }]}
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/background' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/background'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path='/mercury/the-idler' exact render={() => (
      <TitleSectionPage {...props}
        section='mercury/the-idler'
        title='The Mercury'
        facebook='https://www.facebook.com/TheMercurySA/'
        twitter='https://www.twitter.com/TheMercurySA'
        instagram='https://www.instagram.com/the_mercury_sa/'
        description='-'
        publication='The Mercury'
        newspaperTitle='The Mercury on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://mercury.zinioapps.com/shop'
      />
    )} />
    <Route path={'/mercury/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/mercury/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/mercury/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/mercury/:title*([-])*:contentKey([0-9]{5,})'} exact render={() => (
      <RenderArticle {...props} />
    )} />
    <Route path={'/mercury/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default TheMercury
